// Variables
@primaryColor: #3490dc;
@borderColor: #e2e8f0;
@textColor: #4a5568;
@backgroundColor: #ffffff;

// Mixins
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.transition(@property: all, @duration: 0.3s, @timing: ease) {
  transition: @property @duration @timing;
}

.container {
  margin: 0 auto;
  padding: 24px;
  margin-bottom: 5rem;

  @media screen and (max-width: 768px) {
    margin-bottom: 2rem;
  }
}

.topContainer {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
  gap: 16px;

  > h2 {
    font-family: Inter, Inter;
    font-weight: bold;
    font-size: 38px;
    color: #151B26;
    line-height: 45px;
    font-style: normal;
    text-transform: none;
    text-align: center;
    line-height: 1.2;

    @media screen and (max-width: 768px) {
      font-size: 32px;
    }
  }

  > span {
    background: #EEF3FF;
    border-radius: 6px 6px 6px 6px;

    font-family: Inter, Inter;
    font-weight: 400;
    font-size: 16px;
    color: #1E5EFF;
    line-height: 22px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    padding: 8px 16px;
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  // gap: 24px;
  background: #F6F5F4;
  border-radius: 8px;
  padding: 24px;
  cursor: pointer;
}

.icon {
  background-color: lighten(@primaryColor, 35%);
  color: @primaryColor;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  .flexCenter();
  margin-bottom: 16px;
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: @textColor;
  margin-bottom: 8px;
}

.description {
  font-size: 14px;
  color: lighten(@textColor, 20%);
  margin-bottom: 16px;
}

.stats {
  display: flex;
  gap: 16px;
}

.statItem {
  font-size: 14px;
  color: lighten(@textColor, 30%);
}

.imageContainer {
  border-radius: 8px;
  overflow: hidden;
}

.image {
  width: 100%;
  height: auto;
  display: block;
}

.rightColumn {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.featureCard {
  background-color: #F6F5F4;
  // border: 1px solid @borderColor;
  border-radius: 8px;
  // height: 200px;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: #F0EFEE;
  }

  @media screen and (max-width: 768px) {
    min-height: 210px;
  }
}

.iconTitle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  height: 100%;
  padding: 24px;
  gap: 20px;
  .transition(transform);
}

.cardIcon {
  margin-bottom: 8px;
}

.cardTitle {
  font-family: Inter, Inter;
  font-weight: normal;
  font-size: 18px;
  color: #151B26;
  line-height: 28px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.cardDescription {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 24px;
  .transition(top);
}


.cardDescriptionText {
  margin-top: 10px;
  font-size: 14px;
  color: lighten(@textColor, 20%);
}

.featureCard:hover .iconTitle {
  transform: translateY(-100%);
}

.featureCard:hover .cardDescription {
  top: 0;
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 1024px) {
  .rightColumn {
    grid-template-columns: 1fr;
  }
}


